import React, { Component } from 'react';
// import client from '@mailchimp/mailchimp_marketing';

/* client.setConfig({ 
  apiKey: 'd39436a4298ecb2dfed18094a38e802a-us2',
  server: 'us2',
}); */

export class EmailForm extends Component {
  render() {
    return (
      <form action="https://marketing.redkeep.com/pre-launch">
        <input type="submit" value="Sign Up for News" />
      </form>
    );
  }
}

export default EmailForm;
