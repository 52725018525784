module.exports = {
  siteTitle: 'Red Keep', // <title>
  manifestName: 'Eventually',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#BE1E2D',
  manifestThemeColor: '#BE1E2D',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'Red Keep',
  subHeading: 'Reinventing affordable IT operations',
  socialLinks: [
    {
      icon: 'fa-gitlab',
      name: 'Gitlab',
      url: 'https://gitlab.com/redkeep',
    },
    {
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/company/red-keep',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/redkeep',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:sales@redkeep.com',
    },
  ],
};
